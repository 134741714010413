import React from "react";
import { render, unmountComponentAtNode } from "react-dom";

const EmMailIPSelectorComponent = React.lazy(() => import(
    /* webpackChunkName: 'mail-ip-selector' */
    /* prefetch: false */
    "src/custom-elements/mail-ip-selector"
));

const EmDownloadFormComponent = React.lazy(() => import(
    /* webpackChunkName: 'webinare-form' */
    /* prefetch: false */
    "src/custom-elements/download-form"
));

const EmWebinaireFormComponent = React.lazy(() => import(
    /* webpackChunkName: 'webinare-form' */
    /* prefetch: false */
    "src/custom-elements/webinare-form"
));

const EmMailSubSelectorPanelComponent = React.lazy(() => import(
    /* webpackChunkName: 'mail-sub-selector-panel' */
    /* prefetch: false */
    "src/custom-elements/mail-sub-selector-panel"
));

const EmTestComponent = React.lazy(() => import(
    /* webpackChunkName: 'custom-elements-test' */
    /* prefetch: false */
    "src/custom-elements/test"
));

const EmVentesPriveesComponent = React.lazy(() => import(
    /* webpackChunkName: 'custom-elements-ventes-privees' */
    /* prefetch: false */
    "src/custom-elements/ventes-privees"
));

const EmOuvertureCompteComponent = React.lazy(() => import(
    /* webpackChunkName: 'custom-elements-ouverture-compte' */
    /* prefetch: false */
    "src/custom-elements/ouverture-compte"
));

const EmOuvertureCompteM2FComponent = React.lazy(() => import(
    /* webpackChunkName: 'custom-elements/ouverture-compte-m2f' */
    /* prefetch: false */
    "src/custom-elements/ouverture-compte-m2f"
));

const EmMailCreditsSelectorComponent = React.lazy(() => import(
    /* webpackChunkName: 'custom-elements/mail-credits-selector' */
    /* prefetch: false */
    "src/custom-elements/mail-credits-selector"
));

const EmMailSubSelectorComponent = React.lazy(() => import(
    /* webpackChunkName: 'custom-elements/mail-sub-selector' */
    /* prefetch: false */
    "src/custom-elements/mail-sub-selector"
));

const EmProductListComponent = React.lazy(() => import(
    /* webpackChunkName: 'custom-elements/product-list' */
    /* prefetch: false */
    "src/custom-elements/product-list"
));

const EmProductCardComponent = React.lazy(() => import(
    /* webpackChunkName: 'custom-elements/product-card' */
    /* prefetch: false */
    "src/custom-elements/product-card"
));

const EmCoachingFormComponent = React.lazy(() => import(
    /* webpackChunkName: 'custom-elements/product-card' */
    /* prefetch: false */
    "src/custom-elements/coaching-form"
));

const EmLotteryFormComponent = React.lazy(() => import(
    /* webpackChunkName: 'custom-elements/lottery-form' */
    /* prefetch: false */
    "src/custom-elements/lottery-form"
));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function registerAsyncElement<P extends NonNull> (tag: string, Component: React.ComponentType<P>) {
    class EmCustomElement extends HTMLElement {
        connectedCallback () {
            const props = Object.fromEntries(Object.values(this.attributes).map(attribute => [attribute.name, attribute.value] as const)) as P;
            render((
                <div className="em-component-root">
                    <React.Suspense fallback={<>Chargement en cours</>}>
                        <Component {...props} />
                    </React.Suspense>
                </div>
            ), this);
        }

        disconnectedCallback () {
            unmountComponentAtNode(this);
        }
    }

    customElements.define(tag, EmCustomElement);

    console.log(`Registered CustomElement: ${tag}`);

    return EmCustomElement;
}


console.log("Registering CustomElements");
registerAsyncElement("em-test", EmTestComponent);
registerAsyncElement("em-ventes-privees", EmVentesPriveesComponent);
registerAsyncElement("em-ouverture-compte", EmOuvertureCompteComponent);
registerAsyncElement("em-ouverture-compte-m2f", EmOuvertureCompteM2FComponent);
registerAsyncElement("em-mail-credits-selector", EmMailCreditsSelectorComponent);
registerAsyncElement("em-product-list", EmProductListComponent);
registerAsyncElement("em-product-card", EmProductCardComponent);
registerAsyncElement("em-mail-sub-selector", EmMailSubSelectorComponent);
registerAsyncElement("em-mail-ip-selector", EmMailIPSelectorComponent);
registerAsyncElement("em-mail-sub-selector-panel", EmMailSubSelectorPanelComponent);
registerAsyncElement("em-coaching-form", EmCoachingFormComponent);
registerAsyncElement("em-lottery-form", EmLotteryFormComponent);
registerAsyncElement("em-webinare-form", EmWebinaireFormComponent);
registerAsyncElement("em-download-form", EmDownloadFormComponent);